import styled, { keyframes } from 'styled-components'

import breakpoints from '../constants/breakpoints'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const slideUp = keyframes`
  0% {
    transform: translateY(200%)
  }
  100% {
    transform: translateY(0)
  }
`

const grow = keyframes`
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
`

const ContactPageLayout = styled.div`
  width: 100%;

  .section-one {
    display: grid;
    align-items: end;
    grid-template-columns: 2fr 1fr;
    column-gap: 2rem;
    background-color: ${(props) => props.theme.backgroundYellow};
    height: 85vh;
    min-height: 730px;
    padding: 6rem 9rem;

    @media (max-width: ${breakpoints.screenLG}) {
      height: 50vh;
      padding: 6rem 3rem;
    }

    @media (max-width: ${breakpoints.screenMD}) {
      height: 60vh;
      min-height: 600px;
      padding: 6rem 1.5rem;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      grid-template-columns: 1fr;
      height: calc(100vh - 120px);
      min-height: calc(100vh - 120px);
      padding-bottom: 5rem;
    }

    .text-left {
      padding-bottom: 4rem;

      @media (max-width: ${breakpoints.screenL}) {
        padding-bottom: 3rem;
      }

      h1 span {
        color: ${(props) => props.theme.backgroundBlue};
        font-family: ${(props) => props.theme.fontSecondary};
      }

      p {
        position: relative;
        max-width: 10rem;
        padding-bottom: 0.5rem;
        margin-bottom: 2rem;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 3px;
          background-color: ${(props) => props.theme.backgroundPrimary};
          transform-origin: left;
          transform: scale(0);
          animation: ${grow} 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) forwards 0.2s;
        }
      }
    }

    .text-right {
      align-self: end;
      margin-left: auto;
      text-align: right;
      opacity: 0;
      animation: ${fadeIn} 0.8s ease forwards 0.4s;

      p {
        max-width: 15rem;
      }
    }

    .headline {
      overflow: hidden;
    }

    h1 {
      transform: translateY(200%);
      animation: ${slideUp} 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) forwards 0.2s;
    }

    .title {
      opacity: 0;
      animation: ${fadeIn} 0.4s ease forwards 0.2s;
    }
  }

  .section-two {
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 1fr;
    padding: 10rem 9rem;

    @media (max-width: ${breakpoints.screenL}) {
      grid-template-columns: 1.4fr 1fr;
    }

    @media (max-width: ${breakpoints.screenLG}) {
      grid-template-columns: 1fr 1.4fr;
      padding: 6rem 3rem;
    }

    @media (max-width: ${breakpoints.screenMD}) {
      padding: 6rem 1.5rem;
    }

    @media (max-width: ${breakpoints.screenSM}) {
      grid-template-columns: 1fr;
      padding-bottom: 4rem;
    }

    .info {
      display: flex;

      @media (max-width: ${breakpoints.screenL}) {
        justify-content: space-between;
        padding-right: 4rem;
      }

      @media (max-width: ${breakpoints.screenLG}) {
        flex-direction: column;
      }
    }

    .info-right {
      padding-left: 10vw;

      @media (max-width: ${breakpoints.screenL}) {
        padding-left: 6vw;
      }

      @media (max-width: ${breakpoints.screenLG}) {
        padding-left: 0;
      }
    }

    .info-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 3rem;
    }

    .title {
      position: relative;
      padding-bottom: 0.2rem;
      margin-bottom: 0.8rem;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 3px;
        background-color: ${(props) => props.theme.backgroundBlue};
      }
    }

    .link {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 3px;
        right: 0;
        width: 100%;
        height: 1px;
        background-color: ${(props) => props.theme.textPrimary};
      }
    }

    .map {
      height: 100%;
      width: 100%;
      min-height: 460px;
      min-width: 460px;

      @media (max-width: ${breakpoints.screenL}) {
        min-width: 200px;
      }

      @media (max-width: ${breakpoints.screenSM}) {
      }
    }

    .marker {
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translate(-50%, -90%);
      width: 2.6rem;
      height: 2.6rem;
      user-select: none;
    }
  }
`

export default ContactPageLayout
