import React from 'react'

import { colors } from '../../constants/theme'

const Marker = ({ background = colors.yellow, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    className={className}
  >
    <path
      d="M7.49998 0C5.06758 0 2.22968 1.4865 2.22968 5.2703C2.22968 7.8378 6.28378 13.5135 7.49998 15C8.58108 13.5135 12.7703 7.973 12.7703 5.2703C12.7703 1.4865 9.93238 0 7.49998 0Z"
      fill={background}
    />
    <rect x="6" y="4" width="3" height="3" fill="white" />
  </svg>
)

export default Marker
