import React from 'react'
import GoogleMapReact from 'google-map-react'

import imgHero from '../assets/images/home-hero.png'

import SEO from '../components/SEO'
import Header from '../components/Header'
import Marker from '../components/icons/marker'

import { colors } from '../constants/theme'

import ContactPageLayout from '../styles/ContactPageLayout'
import { HeadlineM, ParagraphL, ParagraphM } from '../styles/Typography'

const ContactPage = () => (
  <>
    <SEO title="Mint and Square | Kontakt" image={imgHero} />
    <Header
      logoBackground={colors.blue}
      logoBorder={colors.blue}
      linkText={colors.blue}
      menuLines={colors.blue}
    />
    <ContactPageLayout>
      <div className="section-one">
        <div className="text-left">
          <div className="title">
            <ParagraphM color={colors.white}>Kontakt</ParagraphM>
          </div>
          <div className="headline">
            <HeadlineM color={colors.white}>
              Poveži se <span>z nami</span>
            </HeadlineM>
          </div>
        </div>
        <div className="text-right">
          <ParagraphM color={colors.white} weight="300">
            Ali se najavi na sestanek. Imamo odlično kavo.
          </ParagraphM>
        </div>
      </div>

      <div className="section-two">
        <div className="info">
          <div className="info-left">
            <div className="info-group">
              <ParagraphM className="title">Povpraševanja</ParagraphM>
              <a href="mailto:info@mintandsquare.com">
                <ParagraphL weight="300" className="link">
                  info@mintandsquare.com
                </ParagraphL>
              </a>
            </div>
            <div className="info-group">
              <ParagraphM className="title">Pisarna</ParagraphM>
              <ParagraphL weight="300">
                Tomšičeva 6 <br />
                1000 Ljubljana
              </ParagraphL>
            </div>
          </div>
          <div className="info-right">
            <div className="info-group">
              <ParagraphM className="title">Pokličite nas</ParagraphM>
              <a href="tel:0038651467360">
                <ParagraphL weight="300" className="link">
                  +386 (0) 51 467 360
                </ParagraphL>
              </a>
            </div>
            <div className="info-group">
              <ParagraphM className="title">Podatki o podjetju</ParagraphM>
              <ParagraphL weight="300">
                Agencija 3PR d.o.o. <br /> Žabče 24 <br /> 5220 Tolmin
              </ParagraphL>
            </div>
          </div>
        </div>
        <div className="map">
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyAeqMn1jPOMXs1vU5ltXp_8IPGPJPtrjy0' }}
            defaultCenter={{ lat: 46.05225, lng: 14.5016 }} //14.5004658
            defaultZoom={17}
          >
            <Marker lat="46.0522500" lng="14.5016000" className="marker" />
          </GoogleMapReact>
        </div>
      </div>
    </ContactPageLayout>
  </>
)

export default ContactPage
